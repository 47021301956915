<template>
    <LineChartGenerator
        :chart-data="chartData"
        :chart-id="chartId"
        :chart-options="chartOptions"
        :css-classes="cssClasses"
        :dataset-id-key="datasetIdKey"
        :height="height"
        :plugins="plugins"
        :styles="styles"
        :width="width"
    />
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {
            }
        },
        plugins: {
            type: Array,
            default: () => []
        },
        labels: {
            type: Array,
            default: () => []
        },
        datasets: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: 'index',
                }
            }
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: this.datasets,
            }
        }
    }
}
</script>
