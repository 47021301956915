<template>
    <div class="stats">

        <div class="order-origins">
            <v-btn
                color="primary"
                dark
                class="select-order-origin me-4"
                elevation="0"
                @click="orderOriginCode ='IEC_SCAN_AND_PAY'"
                :outlined="orderOriginCode != 'IEC_SCAN_AND_PAY'"
            >
                {{ $t('orderOrigin1') }}
            </v-btn>
            <v-btn
                color="primary"
                dark
                class="select-order-origin me-4"
                elevation="0"
                @click="orderOriginCode ='IEC_EAT_AND_PAY'"
                :outlined="orderOriginCode != 'IEC_EAT_AND_PAY'"
                v-if="storeId === 0 || storeId === 1"
            >
                {{ $t('orderOrigin2') }}
            </v-btn>
            <v-btn
                color="primary"
                dark
                class="select-order-origin me-4"
                elevation="0"
                @click="orderOriginCode ='IEC_CLICK_AND_COLLECT'"
                :outlined="orderOriginCode != 'IEC_CLICK_AND_COLLECT'"
                v-if="storeId === 0 || storeId === 1"
            >
                {{ $t('orderOrigin3') }}
            </v-btn>
            <v-btn
                color="primary"
                dark
                class="select-order-origin"
                elevation="0"
                @click="orderOriginCode ='IEC_PAY_AND_DRIVE'"
                :outlined="orderOriginCode != 'IEC_PAY_AND_DRIVE'"
                v-if="storeId === 1"
            >
                {{ $t('orderOrigin4') }}
            </v-btn>
        </div>

        <span class="title">{{$t('stats.clientSatisfaction')}}</span>
        <image-rating
            :src="require('@/assets/img/star.png')"
            :item-size="75"
            :show-rating="false"
            :increment="0.1"
            :read-only="true"
            v-model="rating"
            class="mt-8 justify-center fill-width" />
        <p class="text-center font-weight-bold">{{rating}}/5</p>
        <p class="text-center font-weight-bold">{{$t('stats.ratingsNumber', { ratingsNumber: votes })}}</p>

        <div class="horizontal-divider my-8"></div>

        <p class="title">{{$t('stats.ordersNumber')}}</p>
        <LineChart :labels="ordersLabels" :datasets="ordersDatasets"></LineChart>

    </div>
</template>

<script>
import {ImageRating} from 'vue-rate-it';
import LineChart from "../../components/IEC/Charts/LineChart";

export default {
    name: "Stats",
    components: {
        LineChart,
        ImageRating
    },
    data: () => ({
        rating: 4.2,
        votes: 452,
        ordersLabels: [],
        ordersDatasets: [],
        chartsRefresh: 0,
        orderOriginCode: "IEC_SCAN_AND_PAY",
        storeId: JSON.parse(localStorage.getItem('IEC_BO_store')).id,
        ratings: {
            scanAndPay: {
                rating: 4.2,
                votes: 452
            },
            payAtTAble: {
                rating: 4.0,
                votes: 650
            },
            clickAndCollect: {
                rating: 4.4,
                votes: 523
            },
            payAndDrive: {
                rating: 4.3,
                votes: 475
            },
        },
        ordersNumber: {
            scanAndPay: [452, 496, 427, 450, 412, 446, 520, 505, 451, 484, 496, 538],
            payAtTAble: [520, 490, 512, 525, 480, 508, 540, 530, 507, 490, 511, 530],
            clickAndCollect: [115, 122, 107, 115, 125, 108, 112, 106, 109, 118, 113, 106],
            payAndDrive: [730, 710, 712, 726, 714, 718, 706, 721, 715, 710, 700, 709],
        }
    }),
    created() {
        this.updatePageInfo(this.$route.meta)
        this.$root.$on('changeStore', () => {
            this.storeId = JSON.parse(localStorage.getItem('IEC_BO_store')).id
        });

        this.$root.$on('changeLang', () => {
            this.setChart()
        });

        this.setChart()
    },
    methods: {
        setChart(){
            this.ordersLabels = this.$t('months')
            this.ordersDatasets = [
                {
                    label: this.$t('stats.ordersNumber'),
                    data: this.ordersNumber.scanAndPay,
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    tension: 0.3
                }
            ]
            this.chartsRefresh++
        }
    },
    watch: {
        orderOriginCode(code){
            switch (code) {
                case "IEC_SCAN_AND_PAY":
                    this.ordersDatasets[0].data = this.ordersNumber.scanAndPay
                    this.rating = this.ratings.scanAndPay.rating
                    this.votes = this.ratings.scanAndPay.votes
                    break;
                case "IEC_EAT_AND_PAY":
                    this.ordersDatasets[0].data = this.ordersNumber.payAtTAble
                    this.rating = this.ratings.payAtTAble.rating
                    this.votes = this.ratings.payAtTAble.votes
                    break;
                case "IEC_CLICK_AND_COLLECT":
                    this.ordersDatasets[0].data = this.ordersNumber.clickAndCollect
                    this.rating = this.ratings.clickAndCollect.rating
                    this.votes = this.ratings.clickAndCollect.votes
                    break;
                case "IEC_PAY_AND_DRIVE":
                    this.ordersDatasets[0].data = this.ordersNumber.payAndDrive
                    this.rating = this.ratings.payAndDrive.rating
                    this.votes = this.ratings.payAndDrive.votes
                    break;
            }
            this.chartsRefresh++
        }
    }
}
</script>

<style scoped>
.fill-width{
    width: 100%;
}
</style>
